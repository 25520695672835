<template>
  <div class="input_calendar mid">
    <input
      type="text"
      :placeholder="$t('comp.011')"
      v-model="formattedDate"
      ref="dp"
      class="datepicker"
      maxlength="7"
      @click="showPicker"
      @change="changeDt"
      @keydown.prevent="keyinput"
    />
    <button type="button" class="tbl_icon calendar" @mousedown.stop.prevent @click.stop.prevent="togglePicker">{{ $t('comp.014') }}</button>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DatePicker',

  props: {
    value: {
      type: String,
      default: '',
      required: true
    }
  },

  data () {
    return {
      unformattedDate: this.value,
      format: 'YYYY.MM',
      show: false
    }
  },

  computed: {
    formattedDate: {
      set (v) {
        this.unformattedDate = v.replace(/[^\d]/g, '')
      },

      get () {
        return this.unformattedDate.replace(/(\d{4})(\d{1,2}).*/, '$1.$2')
      }
    }
  },

  watch: {
    value () {
      this.unformattedDate = this.value
    }
  },

  mounted () {
    $(this.$refs.dp).MonthPicker({
      ShowIcon: false,
      MonthFormat: 'yy.mm',
      i18n: {
        year: this.$t('comp.009'),
        months: [
          `${this.$t('comp.1_month')}`,
          `${this.$t('comp.2_month')}`,
          `${this.$t('comp.3_month')}`,
          `${this.$t('comp.4_month')}`,
          `${this.$t('comp.5_month')}`,
          `${this.$t('comp.6_month')}`,
          `${this.$t('comp.7_month')}`,
          `${this.$t('comp.8_month')}`,
          `${this.$t('comp.9_month')}`,
          `${this.$t('comp.10_month')}`,
          `${this.$t('comp.11_month')}`,
          `${this.$t('comp.12_month')}`
        ]
      },
      OnAfterChooseMonth: (v) => {
        this.unformattedDate = moment(v).format('YYYYMM')
        this.emitChange()
      },

      OnBeforeMenuOpen: () => {
        this.show = true
      },

      OnAfterMenuClose: () => {
        this.show = false
      }
    })
  },

  methods: {
    keyinput (e) {
      this.hidePicker()
    },

    showPicker (e) {
      $(this.$refs.dp).MonthPicker('Open')
      this.show = true
    },

    hidePicker (e) {
      $(this.$refs.dp).MonthPicker('Close')
      this.show = false
    },

    togglePicker (e) {
      if (this.show) {
        this.hidePicker()
      } else {
        this.showPicker()
      }
    },

    emitChange () {
      this.$emit('input', this.unformattedDate)
    },

    changeDt (e) {
      const t = e.target
      const m = t.value.match(/(\d+)\.(\d+)/)
      t.value = t.oldValue ?? ''

      if (m) {
        const mo = moment({
          year: parseInt(m[1]),
          month: parseInt(m[2]) - 1
        })

        if (mo.isValid()) {
          t.value = mo.format(this.format)
          t.oldValue = t.value
        }
      }

      this.formattedDate = t.value
      this.emitChange()
    }
  }
}
</script>

<style>
.month-picker .ui-button-text {
  padding: .4em 0;
  font-size: 12.6px;
}
</style>
